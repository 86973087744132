/* eslint-disable object-curly-newline */
import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { CustomButton, PaymentPage } from '../assets/styled';
import Table from '../components/Table';
import * as thunks from '../store/thunks';
import SendNotifications from '../components/modals/SendNotifications';
import { NotificationGroups } from '../commons/constants';

function Notifications() {
  const dispatch = useDispatch();
  const getNotifications = useCallback(() => dispatch(thunks.getNotifications()), [dispatch]);
  const data = useSelector(state => state.notifications);

  const [notiModal, setNotiModal] = useState(false);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  const columns = [
    {
      name: 'id',
      text: 'Id',
      filterable: true,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
    },
    {
      name: 'createdAt',
      text: 'Tarih',
      filterable: true,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <p>{new Date(value).toLocaleDateString()}</p>
      ),
    },
    {
      name: 'title',
      text: 'Başlık',
      filterable: true,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
    },
    {
      name: 'body',
      text: 'İçerik',
      filterable: true,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
    },
    {
      name: 'group',
      text: 'Grup',
      filterable: true,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <p>{NotificationGroups.find(g => g.id === value)?.name || '-'}</p>
      ),
    },
    {
      name: 'count',
      text: 'Gönderilen Cihaz',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
      customBody: value => (
        <p>{new Intl.NumberFormat('tr-TR').format(value)}</p>
      ),
    },
  ];

  return (
    <PaymentPage>
      <Grid container>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', margin: '15px 0' }}>
          <h3>Bilidirmler</h3>
          <CustomButton purple onClick={() => setNotiModal(true)}>Bilirim Gönder</CustomButton>
        </div>
        <Grid item lg={12} xs={12}>
          <Table
            data={data || []}
            columns={columns || []}
            id="reportsTable"
            options={{
              selectableRows: 'none',
              header: {
                visible: true,
                columnSetting: false,
              },
              filter: {
                show: true,
                filters: [],
                // onChange: (state, value, text, type) => console.log(state, value, text, type),
                // drawerChange: () => setState(prevstate => ({ filterDrawer: !prevstate.filterDrawer })),
              },
              pagination: {
                active: false,
                perPage: 20,
              },
            }}
            // rowClick={row => console.log(row)}
            // rowClick={() => window.location.replace('/SuggestionDetail')}
          />
        </Grid>
      </Grid>
      <SendNotifications open={notiModal} onClose={() => setNotiModal(false)} />
    </PaymentPage>
  );
}

export default Notifications;
