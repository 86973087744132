// MAIN URL
export const MAIN_URL = 'https://apiv2.hafizamerkezi.com/';
// export const MAIN_URL = 'http://localhost:3000/';

// USER URL
export const INIT = `${MAIN_URL}private/v1/User/init`;
export const STATICS = `${MAIN_URL}admin/v1/AdminStatics`;

// LOGIN URL
export const LOGIN = `${MAIN_URL}account/v1/Account/login`;
export const REGISTER = `${MAIN_URL}account/v1/Account/register`;

// ADMIN URL
export const LANGUAGE_ADMIN = `${MAIN_URL}admin/v1/Language`;
export const HELPERS = `${MAIN_URL}admin/v1/Helpers`;

// LANGUAGE URL
export const LANGUAGE = `${MAIN_URL}private/v1/Language`;

// WORDS URL
export const WORD = `${MAIN_URL}admin/v1/Word`;
export const WORD_PR = `${MAIN_URL}private/v1/Word`;

// CATEGORY URL
export const CATEGORY = `${MAIN_URL}admin/v1/Category`;
export const CATEGORY_PR = `${MAIN_URL}private/v1/Category`;

// USER URL
export const USER = `${MAIN_URL}admin/v1/User`;

// PACKAGES URL
export const PACKAGE = `${MAIN_URL}admin/v1/Package`;
export const PACKAGE_PR = `${MAIN_URL}admin/v1/Account/Package`;
export const PACKAGE_ASSING = `${MAIN_URL}admin/v1/Package/assignPackage`;

export const COURSES = `${MAIN_URL}admin/v1/Course/getCourses`;

// PAYMENT URL
export const CREATE_PAYMENT = `${MAIN_URL}admin/v1/Package/createPayment`;

// COUPON URL
export const COUPON = `${MAIN_URL}admin/v1/Coupon`;
// export const COUPON_PR = `${MAIN_URL}private/v1/Coupon`;

export const BLOG = `${MAIN_URL}admin/v1/Blogs`;
export const PAYMENT_LIST = `${MAIN_URL}admin/v1/Package/paymentList`;
export const CHANGE_PAYMENT_STATUS = `${MAIN_URL}admin/v1/Package/changePaymentStatus`;
export const BULK_SALES = `${MAIN_URL}admin/v1/BulkSales`;
export const NOTIFICATIONS = `${HELPERS}/getNotifications`;


export const payment_types = [
  { id: 'DEBIT', name: 'Havale' },
  { id: 'CREDIT', name: 'Kredi Kartı' },
];

export const payment_status = [
  { id: 'PENDING', name: 'Beklemede' },
  { id: 'COMPLETED', name: 'Başarılı' },
  { id: 'FAILED', name: 'Başarısız' },
];

export const bugTypes = [
  { id: 'ALL', name: 'Hepsi' },
  { id: 'IMAGE', name: 'Görsel' },
  { id: 'MEMORY_VOICE', name: 'Hafıza Sesi' },
  { id: 'EXAMPLE_VOICE', name: 'Örnek Seslerinde' },
  { id: 'OTHER', name: 'Diğer' },
];

export const NotificationGroups = [
  { id: 'ALL', name: 'Herkes' },
  { id: 'REGISTER', name: 'Kayıtlı Kullanıcılar' },
  { id: 'NOT_REGISTER', name: 'Kayıt Olmamışlar' },
  { id: 'NOT_BUYER', name: 'Paket Almayanlar' },
  { id: 'BUYER', name: 'Paketi Olanlar' },
  { id: 'NOT_BUY_SELECT_LANG_EN', name: 'İngilizce İsteyenler  (İngilizce ve çocuk ing)', lang: [1, 12] },
  { id: 'NOT_BUY_SELECT_LANG_AR', name: 'Arapça İsteyenler (Tüm Arapçalar)', lang: [2, 3, 25, 26, 27, 28, 29, 30, 31, 32] },
  { id: 'BUY_SELECT_LANG_EN', name: 'İngilizce Alanlar (İngilizce ve çocuk ing)', lang: [10, 11] },
  { id: 'BUY_SELECT_LANG_AR', name: 'Arapça Alanlar (Kur"an, Pratik ve İmam)', lang: [28, 29] },
  { id: 'BUY_SELECT_LANG_ALL', name: 'Tüm Diller Alanlar', lang: [1] },
];

export const DAYS = ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'];
