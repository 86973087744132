import * as constants from './constants';

const initialState = {
  isLogin: false,
  activeUser: {},
  appConfig: {
    sidebar: false,
  },
  toast: { open: false, message: '', variant: 'success' },
  allLanguages: [],
  language: {},
  allWord: [],
  word: [],
  wordDetail: {},
  category: [],
  categorySub: [],
  allUsers: [],
  user: {},
  allCoupon: [],
  statics: [],
  blogs: [],
  coupon: {},
  lastUplaodFile: '',
  paymentList: [],
  userPaymentList: [],
  bulkSales: [],
  wordReports: [],
  courses: [],
  notifications: [],
};

export default function store(state = initialState, action) {
  const actions = action;
  switch (actions.type) {
    case constants.SET_TOAST: {
      const oldToast = state.toast;
      const toast = { open: action.value, message: action.message, variant: action.variant };
      if (!action.value) {
        toast.message = '';
        toast.variant = oldToast.variant;
      }
      return { ...state, toast };
    }
    case constants.SET_ACTIVE_USER: {
      return { ...state, activeUser: action.data };
    }
    case constants.APP_CONFIG: {
      const appConfig = { ...state.appConfig };
      appConfig[action.key] = action.value;
      return { ...state, appConfig };
    }
    case constants.SET_ALL_LANGUAGES: {
      return { ...state, allLanguages: action.data };
    }
    case constants.SET_LANGUAGE: {
      return { ...state, language: action.data };
    }
    case constants.SET_ALL_WORD: {
      return { ...state, allWord: action.data };
    }
    case constants.SET_WORD: {
      return { ...state, word: action.data };
    }
    case constants.SET_WORD_DETAIL: {
      return { ...state, wordDetail: action.data };
    }
    case constants.SET_CATEGORY: {
      return { ...state, category: action.data };
    }
    case constants.SET_CATEGORYSUB: {
      return { ...state, categorySub: action.data };
    }
    case constants.SET_ALL_USER: {
      return { ...state, allUsers: action.data };
    }
    case constants.SET_USER: {
      return { ...state, user: action.data };
    }
    case constants.SET_ALL_PACKAGE: {
      return { ...state, allPackage: action.data };
    }
    case constants.SET_PACKAGE: {
      return { ...state, package: action.data };
    }
    case constants.SET_ALL_COUPON: {
      return { ...state, allCoupon: action.data };
    }
    case constants.SET_COUPON: {
      return { ...state, coupon: action.data };
    }
    case constants.SET_STATICS: {
      return { ...state, statics: action.data };
    }
    case constants.SET_BLOGS: {
      return { ...state, blogs: action.data };
    }
    case constants.SET_LAST_UPLOAD_FILE: {
      return { ...state, lastUplaodFile: action.url };
    }
    case constants.SET_PAYMENT_LIST: {
      return { ...state, paymentList: action.data };
    }
    case constants.SET_USER_PAYMENT_LIST: {
      return { ...state, userPaymentList: action.data };
    }
    case constants.SET_BULK_SALES: {
      return { ...state, bulkSales: action.data };
    }
    case constants.SET_WORD_REPORTS: {
      return { ...state, wordReports: action.data };
    }
    case constants.SET_COURSES: {
      return { ...state, courses: action.data };
    }
    case constants.SET_NOTIFICATIONS: {
      return { ...state, notifications: action.data };
    }
    default: {
      return state;
    }
  }
}
