/* eslint-disable react/prop-types */
import {
  Backdrop, MenuItem, Modal, TextField,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { Close } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { CustomButton, CustomFade, CustomIconButton } from '../../assets/styled';
import * as thunks from '../../store/thunks';
import { NotificationGroups } from '../../commons/constants';

function SendNotifications({ open, onClose }) {
  const [warningOpen, setWarningOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [group, setGroup] = useState('');

  const dispatch = useDispatch();
  const sendNotify = useCallback(data => dispatch(thunks.sendNotify(data)), [dispatch]);

  const saveFunc = (checked) => {
    if (!checked) {
      setWarningOpen(true);
      return;
    }
    const data = {
      title,
      content,
      group,
    };
    if (NotificationGroups.find(item => item.id === group)?.lang) {
      data.lang = NotificationGroups.find(item => item.id === group).lang;
    }
    sendNotify(data);
    setWarningOpen(false);
    onClose();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => onClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <CustomFade in={open}>
          <div>
            <div className="header">
              <h1>Bildirimi Gönder</h1>
              <CustomIconButton onClick={() => onClose()}><Close /></CustomIconButton>
            </div>
            <div className="content">
              <div className="form">
                <TextField
                  fullWidth
                  variant="standard"
                  label="Bildirim Başlığı"
                  type="text"
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                />
                <TextField
                  fullWidth
                  variant="standard"
                  label="Bildirim İçeriği"
                  type="text"
                  value={content}
                  onChange={e => setContent(e.target.value)}
                />
                <TextField
                  select
                  fullWidth
                  variant="standard"
                  label="Gönderilecek Grup"
                  value={group}
                  onChange={e => setGroup(e.target.value)}
                >
                  {
                    NotificationGroups.map(item => (
                      <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                    ))
                  }
                </TextField>
                <div className="buttonWrapper">
                  <CustomButton onClick={() => onClose()} lined>
                    Vazgeç
                  </CustomButton>
                  <CustomButton purple onClick={() => saveFunc()}>
                    Gönder
                  </CustomButton>
                </div>
              </div>
            </div>
          </div>
        </CustomFade>
      </Modal>
      {
        warningOpen && (
          <Modal
            open={warningOpen}
            onClose={() => setWarningOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <CustomFade in={open}>
              <div>
                <div className="header">
                  <h1>DİKKAT!</h1>
                  <CustomIconButton onClick={() => setWarningOpen(false)}><Close /></CustomIconButton>
                </div>
                <div className="content">
                  <div className="form" style={{ color: 'white' }}>
                    <p>Cihazlara Bildirim gönderiyorsunuz.</p>
                    <p>Eğer onaylarsanız kullanıcılara bildirim gidecektir.</p>
                    <p>Onaylıyor musunuz?</p>
                    <div className="buttonWrapper">
                      <CustomButton onClick={() => setWarningOpen(false)} lined>
                        Vazgeç
                      </CustomButton>
                      <CustomButton purple onClick={() => saveFunc(true)}>
                        Bildirimleri Gönder
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </div>
            </CustomFade>
          </Modal>
        )
      }
    </>
  );
}

export default SendNotifications;
