/* eslint-disable react/prop-types */
import React from 'react';
import { Backdrop, Modal } from '@material-ui/core';
import { CustomFade, TabbleStatusBox } from '../../assets/styled';
import Table from '../Table';
import { DAYS } from '../../commons/constants';

function CourseUsers({ open, onClose, data }) {
  const columns = [
    {
      name: 'User',
      text: 'Id',
      editable: false,
      align: 'left',
      type: 'text',
      customBody: value => (
        <p>{value.id}</p>
      ),
    },
    {
      name: 'User',
      text: 'Adı',
      editable: false,
      align: 'left',
      type: 'text',
      customBody: value => (
        <p>{value.name}</p>
      ),
    },
    {
      name: 'User',
      text: 'Mail',
      editable: false,
      align: 'left',
      type: 'text',
      customBody: value => (
        <p>{value.mail}</p>
      ),
    },
    {
      name: 'createdAt',
      text: 'Kayıt Tarihi',
      filterable: true,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <p>{new Date(value).toLocaleDateString()}</p>
      ),
    },
    {
      name: 'payment_id',
      text: 'Ödeme Kayıt No',
      editable: false,
      align: 'left',
      type: 'text',
    },
    {
      name: 'selected_days',
      text: 'Seçilen Gün',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      downloadable: true,
      customBody: value => (
        <TabbleStatusBox status={3}>
          {value.days.map(d => (
            <p>{`${DAYS[d]},`}</p>
          ))}
          {value.hours.map(h => (
            <span>{` - ${h}`}</span>
          ))}
        </TabbleStatusBox>
      ),
    },
  ];

  return (
    <>
      <Modal
        open={open}
        onClose={() => onClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <CustomFade
          in={open}
          style={{
            maxWidth: '80%',
            margin: 'auto',
            width: '80%',
          }}
        >
          <div>
            <div className="header">
              <h1>Kayıtlı Kullanıcılar ({data.name})</h1>
            </div>
            <div className="content">
              <Table
                data={data?.Users || []}
                columns={columns || []}
                id="reportsTable"
                options={{
                  selectableRows: 'none',
                  header: {
                    visible: true,
                    columnSetting: false,
                  },
                  filter: {
                    show: true,
                    filters: [],
                    // onChange: (state, value, text, type) => console.log(state, value, text, type),
                    // drawerChange: () => setState(prevstate => ({ filterDrawer: !prevstate.filterDrawer })),
                  },
                  pagination: {
                    active: false,
                    perPage: 20,
                  },
                }}
                // rowClick={row => console.log(row)}
                // rowClick={() => window.location.replace('/SuggestionDetail')}
              />
            </div>
          </div>
        </CustomFade>
      </Modal>
    </>
  );
}

export default CourseUsers;
