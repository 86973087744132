/* eslint-disable object-curly-newline */
import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Check, Close } from '@material-ui/icons';
import { CustomButton, PaymentPage, TabbleStatusBox } from '../assets/styled';
import Table from '../components/Table';
import * as thunks from '../store/thunks';
import BulkSalesModal from '../components/modals/BulkSalesModal';
import CourseUsers from '../components/modals/CourseUsers';
import { DAYS } from '../commons/constants';

function Course() {
  const dispatch = useDispatch();
  const getCourses = useCallback(() => dispatch(thunks.getCourses()), [dispatch]);
  const data = useSelector(state => state.courses);

  const [openModal, setOpenModal] = useState(null);
  const [usersModal, setUsersModal] = useState({ open: false, data: {} });

  useEffect(() => {
    getCourses();
  }, [getCourses]);

  const columns = [
    {
      name: 'id',
      text: 'Id',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
    },
    {
      name: 'name',
      text: 'Kurs Adı',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
    },
    {
      name: 'start_date',
      text: 'Başlangıç Tarihi',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
      customBody: value => (
        <p>{new Date(value).toLocaleDateString()}</p>
      ),
    },
    {
      name: 'end_date',
      text: 'Bitiş Tarihi',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
      customBody: value => (
        <p>{new Date(value).toLocaleDateString()}</p>
      ),
    },
    {
      name: 'price',
      text: 'Fiyat',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
      customBody: value => (
        <p>{new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(value)}</p>
      ),
    },
    {
      name: 'days',
      text: 'Günler',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      downloadable: true,
      customBody: value => (
        value?.map(day => (
          <TabbleStatusBox status={day.isFull ? 2 : 3}>
            {day.isFull ? <Close /> : <Check />}
            {day.days.map(d => (
              <p>
                {`${DAYS[d]},`}
              </p>
            ))}
            {day.hours.map(h => (
              <span>{` - ${h}`}</span>
            ))}
          </TabbleStatusBox>
        ))
      ),
    },
    {
      name: 'max_students',
      text: 'Kontenjan',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
    },
    {
      name: 'Users',
      text: 'Kayıt Yaptıranlar',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
      customBody: (value, a, b, row) => (
        value.length > 0 ? (
          <CustomButton purple onClick={() => setUsersModal({ open: true, data: row })}>Göster ({value?.length} Kişi)</CustomButton>
        ) : 'Kayıt Yok'
      ),
    },
  ];

  return (
    <PaymentPage>
      <Grid container>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', margin: '15px 0' }}>
          <h3>Kurslar</h3>
          {/* <CustomButton purple onClick={() => setOpenModal(true)}>Kurs Ekle</CustomButton> */}
        </div>
        <Grid item lg={12} xs={12}>
          <Table
            data={data || []}
            columns={columns || []}
            id="reportsTable"
            options={{
              selectableRows: 'none',
              header: {
                visible: true,
                columnSetting: false,
              },
              filter: {
                show: true,
                filters: [],
                // onChange: (state, value, text, type) => console.log(state, value, text, type),
                // drawerChange: () => setState(prevstate => ({ filterDrawer: !prevstate.filterDrawer })),
              },
              pagination: {
                active: false,
                perPage: 20,
              },
            }}
            // rowClick={row => console.log(row)}
            // rowClick={() => window.location.replace('/SuggestionDetail')}
          />
        </Grid>
      </Grid>
      {
        openModal && (
          <BulkSalesModal
            open={openModal}
            onClose={() => setOpenModal(false)}
          />
        )
      }
      {
        usersModal.open && (
          <CourseUsers
            open={usersModal.open}
            onClose={() => setUsersModal({ open: false, data: {} })}
            data={usersModal.data}
          />
        )
      }
    </PaymentPage>
  );
}

export default Course;
