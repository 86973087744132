import {
  ClickAwayListener, Fade, IconButton, Paper,
} from '@material-ui/core';
import { MeetingRoom, KeyboardArrowDown } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  // Bell,
  // Gear,
  Hamburger,
} from '../assets/icons';
import { Colors } from '../assets/statics';
import {
  CustomButton, CustomIconButton, CustomPopperUser, HeaderWrapper,
} from '../assets/styled';
import { setAppConfig, setActiveUser } from '../store/actions';

const headerTitleData = [
  { text: 'Ana Sayfa', hash: '/' },
  { text: 'Kullanıcılar', hash: '/Users' },
  { text: 'Diller', hash: '/Languages' },
  { text: 'Kategori', hash: '/Categories' },
  { text: 'Kelimeler', hash: '/Words' },
  { text: 'Paketler', hash: '/Packages' },
  { text: 'Kuponlar', hash: '/Coupons' },
  { text: 'Ayarlar', hash: '/WebPage' },
  { text: 'WebPage', hash: '/WebPage' },
  { text: 'Bloglar', hash: '/Blogs' },
  { text: 'Yeni Blog', hash: '/NewBlog' },
  { text: 'Blog Detay', hash: '/Blog' },
  { text: 'Yardımcılar', hash: '/Helpers' },
  { text: 'Ödemeler', hash: '/Payments' },
  { text: 'Toplu Satışlar', hash: '/BulkSales' },
  { text: 'Bug Reports', hash: '/Bug-reports' },
  { text: 'Kurslar', hash: '/Course' },
  { text: 'Bildirimler', hash: '/Notifications' },
];

function Header() {
  const sidebarStatu = useSelector(state => state.appConfig.sidebar);
  // const headerTitle = useSelector(state => state.appConfig.headerTitle);
  const [userAnchor, setUserAnchor] = useState(false);
  const [headerTitleState, setHeaderTitleState] = useState(false);
  const dispatch = useDispatch();
  const setSidebarStatu = useCallback(() => dispatch(setAppConfig('sidebar', !sidebarStatu)), [dispatch, sidebarStatu]);
  // const setHeaderTitle = useCallback(() => dispatch(setAppConfig('headerTitle', headerTitleState)), [dispatch, headerTitle]);
  const activeUser = useSelector(state1 => state1.activeUser);

  function logOut() {
    dispatch(setActiveUser({}));
    localStorage.removeItem('token');
    window.location.href = '#/Login';
  }

  useEffect(() => {
    if (window.location.hash.split('/')[1] !== headerTitleState) {
      const index = headerTitleData.find((x, i) => (window.location.hash !== '#/' ? (i !== 0 && window.location.hash.split('/')[1]?.includes(x.hash.split('/')[1])) : headerTitleData[0]));
      // console.log(index.text, headerTitleState);
      setHeaderTitleState(index.text);
      // setHeaderTitle();
    }
  }, [window.location.hash]);

  return (
    <HeaderWrapper sideOpen={sidebarStatu}>
      <div className="fixed">
        <div className="left">
          <IconButton onClick={setSidebarStatu}>
            <Hamburger />
          </IconButton>
          <span>
            {headerTitleState}
          </span>
        </div>
        <div className="right">
          {/* <IconButton>
            <Bell />
            <span className="counter">4</span>
          </IconButton> */}
          <div className="profile">
            <div className="capitalNameBox">
              <span>{activeUser?.name?.split(' ')?.[0]?.substring(0, 1)}{activeUser?.name?.split(' ')?.[1]?.substring(0, 1)}</span>
            </div>
            <div className="name">
              <h4>{activeUser?.name}</h4>
              <p>Owner</p>
            </div>
            <CustomIconButton onClick={e => setUserAnchor(e.currentTarget)}><KeyboardArrowDown style={{ fill: Colors.lightWhite }} /></CustomIconButton>
          </div>
        </div>
      </div>
      {
        userAnchor && (
          <CustomPopperUser open={Boolean(userAnchor)} anchorEl={userAnchor} placement="bottom-end" transition>
            {({ TransitionProps }) => (
              <ClickAwayListener onClickAway={() => setUserAnchor(false)}>
                <Fade {...TransitionProps} timeout={500}>
                  <Paper style={{ display: 'flex', flexDirection: 'column' }}>
                    {/* <CustomButton simple startIcon={<Gear />}><Simplelink to="/Admin" onClick={() => setUserAnchor(false)}>Ayarlar</Simplelink></CustomButton> */}
                    <CustomButton simple startIcon={<MeetingRoom />} onClick={() => { logOut(); setUserAnchor(false); }}>Çıkış</CustomButton>
                    <div className="triangle" />
                  </Paper>
                </Fade>
              </ClickAwayListener>
            )}
          </CustomPopperUser>
        )
      }
    </HeaderWrapper>
  );
}

export default Header;
