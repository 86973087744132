import React from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ChartWrapper, PageWrapper } from '../assets/styled';
import Widgets from '../components/Widgets';
import Bar from '../components/chart/Bar';
// import Line from '../components/chart/Line';
import Pie from '../components/chart/Pie';
import Table from '../components/Table';

const columns = [
  {
    name: 'id',
    text: 'ID',
    filterable: true,
    align: 'left',
    sortable: true,
    type: 'text',
  },
  {
    name: 'User',
    text: 'Satın alan',
    filterable: true,
    align: 'left',
    sortable: true,
    type: 'text',
    customBody: value => (
      <span>{value.name} - {value.mail}</span>
    ),
  },
  {
    name: 'start_date',
    text: 'Tarih',
    filterable: true,
    align: 'left',
    sortable: true,
    type: 'text',
    customBody: value => (
      <span>{moment(value).format('DD.MM.YYYY')}</span>
    ),
  },
  {
    name: 'Package',
    text: 'Alınna Paket',
    filterable: true,
    align: 'left',
    sortable: true,
    type: 'text',
    customBody: value => (
      <span>{value.name}</span>
    ),
  },
  {
    name: 'Payment',
    text: 'Ödeme Tipi',
    filterable: true,
    align: 'left',
    sortable: true,
    type: 'text',
    customBody: value => (
      <span>{value?.is_bulk ? 'Toplu Satış Kaydı' : value?.payment_type}</span>
    ),
  },
  {
    name: 'Payment',
    text: 'Kupon',
    filterable: true,
    align: 'left',
    sortable: true,
    type: 'text',
    customBody: value => (
      <span>{value?.coupon_code || '-'}</span>
    ),
  },
  {
    name: 'Payment',
    text: 'Taksit',
    filterable: true,
    align: 'left',
    sortable: true,
    type: 'text',
    customBody: value => (
      <span>{value?.installment || '-'}</span>
    ),
  },
  {
    name: 'price',
    text: 'Fiyat',
    filterable: true,
    align: 'left',
    sortable: true,
    type: 'text',
    customBody: value => (
      <span>{value && new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(value || 0)}</span>
    ),
  },
];

function DashboardHook() {
  const activeUser = useSelector(state1 => state1.activeUser);
  const list = useSelector(state1 => state1.statics?.data);
  const statics = useSelector(state1 => state1.statics?.extras);

  return (
    activeUser?.user_type_id === 1 && (
      <PageWrapper>
        <Grid container spacing={3}>
          {
            list && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={3} style={{ height: 'calc(100% + 24px)' }}>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Widgets type="sm" title="Toplam Satılan Paket" numberData={`Toplam ${statics.totalSalesCount} Adet`} icon={null} />
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Widgets type="sm" title="Toplam Satılan Miktar" numberData={new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(statics.totalSales)} />
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Widgets type="sm" title="Geçen Ay Satılan" numberData={`${statics.lastMonthSalesCount} Adet - ${new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(statics.lastMonthSales)}`} />
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Widgets type="sm" title="Bu Ay Satılan" numberData={`${statics.currentMonthSalesCount} Adet - ${new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(statics.currentMonthSales)}`} />
                  </Grid>

                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Widgets type="sm" title="Kurslar" numberData={`${statics.courses?.count} Adet - ${new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(statics.courses?.amount)}`} />
                  </Grid>

                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Widgets type="sm" title="Toplu Satış Miktar" numberData={new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(statics.totalBulk)} />
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Widgets type="sm" title="Toplu Satışdan Kaydolan" numberData={statics.totalBulkMember} />
                  </Grid>
                </Grid>
              </Grid>
            )
          }
          {/* <Grid item lg={6} md={6} sm={12} xs={12}>
            <ChartWrapper height="500px">
              <Pie debit={statics?.debitCount || 0} credit={statics?.creditCount || 0} />
            </ChartWrapper>
          </Grid> */}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ChartWrapper height="500px">
              <Bar statics={statics?.monthData || []} />
            </ChartWrapper>
          </Grid>
          <Grid item lg={12} xs={12}>
            <Table
              data={list || []}
              columns={columns || []}
              id="reportsTable"
              options={{
                selectableRows: 'none',
                header: {
                  visible: true,
                  columnSetting: false,
                },
                filter: {
                  show: true,
                  filters: [],
                  // onChange: (state, value, text, type) => console.log(state, value, text, type),
                  // drawerChange: () => setState(prevstate => ({ filterDrawer: !prevstate.filterDrawer })),
                },
                pagination: {
                  active: true,
                  perPage: 20,
                },
              }}
              // rowClick={row => console.log(row)}
              // rowClick={() => window.location.replace('/SuggestionDetail')}
            />
          </Grid>
          {/* <Grid item lg={12} md={12} sm={12} xs={12}>
            <ChartWrapper height="500px">
              <Line />
            </ChartWrapper>
          </Grid> */}
        </Grid>
      </PageWrapper>
    )
  );
}

export default DashboardHook;
